<template>
  <div class="review">
    <div v-if="feedbackSend" class="review-body">
      <h2 style="font-size: 24px">Спасибо за отзыв!</h2>
    </div>
    <div v-else class="review-body">
      <h2 style="font-size: 24px">Понравилась игра?</h2>
      <form @submit.prevent="handleSubmit()">
        <!-- rating -->
        <star-rating
          v-model="rating"
          :star-size="40"
          :show-rating="false"
          :read-only="feedbackSend"
        />
        <!-- comment textarea -->
        <textarea
          v-show="ratingSent"
          v-model="comment"
          type="text"
          placeholder="Отзыв или комментарий. Эти сообщения прочитают разработчики игры"
          class="geecko-textarea"
        />
        <!-- send feedback button -->
        <div
          v-if="ratingSent"
          class="flex flex-row flex-wrap w-full"
          :style="{
            paddingTop: '10px',
          }"
        >
          <button class="geecko-button" type="submit" :disabled="rate">
            Отправить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: 'Feedback',
  components: { StarRating },
  props: {},
  data() {
    return {
      rating: 0,
      ratingSent: false,
      comment: "",
      submitting: false,
      feedbackSend: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]
    },
    rate() {
      if (this.comment && this.rating) {
        return false;
      }
      return true;
    },
  },
  watch: {
    rating: {
      async handler (to, from) {
        if (to > 0) {
          this.$log('W rating: feedback SEND rating', to)
          await this.$http.post("/feedback", { rating: to, comment: this.comment })
          this.ratingSent = true
        }
      }
    }
  },
  mounted() {},
  methods: {
    handleSubmit() {
      this.$log("handleSubmit");
      if (this.comment.length === 0) {
        // this.$toaster.error('failure');
        return;
      }
      this.submitting = true;
      this.$http
        .post("/feedback", { rating: this.rating, comment: this.comment })
        .then(() => {
          // this.$toaster.success('success');
          this.feedbackSend = true
          this.comment = ""
          this.rating = 0
          this.ratingSent = false
        })
        .catch(() => {
          // this.$toaster.error('failure');
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang='scss'>
.review {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
  background-color: #131120;
  position: relative;
  &-body {
    padding: 25px;
  }
}
.geecko-textarea {
  margin-top: 15px;
  padding: 10px;
  background: #2e2c3f;
  outline: none;
  border: 0px solid;
  color: #aeadb1;
  font-family: "BlenderPro";
  width: 100%;
  max-height: 80px;
  min-height: 90px;
  max-width: 520px;
  // min-width: 520px;
  width: 100%;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .review {
    &-body {
      & h2 {
        font-size: 26px;
        line-height: 36px;
      }
    }
  }
}
</style>
