<template>
  <div class="final-page">
    <GameHeader />
    <!-- <router-link to="/top" class="top-btn">Турнирная таблица</router-link> -->
    <div class="final-page-block">
      <div class="final-page-block-city" />
      <div class="final-page-block-clouds" />
      <div class="final-page-block-city-bg" />
      <div class="final-page-block-blur-bottom" />
      <div class="final-page-block-content">
        <h1 style="font-size: 48px">Ты прошёл игру</h1>
        <div class="final-page-block-subtitle">
          <p>
            Это был славный путь. Ты доказал всем, что ты крут. Последняя гильза
            упала на землю. Все они были мертвы
          </p>
        </div>
      </div>
    </div>
    <div
      class="final-page-container"
      v-if="top && top.current && top.current.rank"
    >
      <h2>
        <span style="color: #ffef40">{{ top.current.rank }} место</span> в
        турнире
      </h2>
    </div>
    <div class="final-page-container" v-if="currentConf">
      <top-table
        :currentUsers="currentUsers"
        :currentUser="currentUser"
        :currentDate="currentDate"
        showCurrentUser
      />
    </div>
    <div class="final-page-container">
      <div
        style="
          margin-top: 0px;
          margin-bottom: 0px;
          width: 100%;
          max-width: 600px;
          padding-left: 25px;
          background: #131120;
          padding: 0px 25px;
          padding-top: 25px;
        "
      >
        <Jobs />
      </div>
    </div>
    <!-- <div class="final-page-container">
      <prize actionText="Пройти задание" />
    </div> -->
    <div class="final-page-container final-page-container__result" style="margin-bottom: 40px; margin-top: 140px;">
      <result-block :stats="stats" />
    </div>
    <div class="final-page-container">
      <social-share-block />
    </div>
    <div class="final-page-container">
      <Feedback />
    </div>
    <div
      class="final-page-container mb-20">
      <div
        class=""
        style="
          margin-top: 40px;
          width: 100%;
          max-width: 600px;
          padding-left: 0px;
        "
      >
        <button
          v-if="$store.getters.isLocal"
          class="geecko-button" @click="restart()">Новая игра</button>
      </div>
    </div>
    <Footer class="w-screen"/>
  </div>
</template>

<script>
import ResultBlock from "@/components/Result/ResultBlock";
import Feedback from "@/components/Result/Feedback";
import Prize from "@/components/Result/Prize";
import GameHeader from "@/components/Game/GameHeader.vue";
import TopTable from "@/components/UI/TopTable";
import SocialShareBlock from "@/components/Result/SocialShareBlock";
import Jobs from "@/components/Result/Jobs";
import Footer from "@/components/UI/Footer";

export default {
  name: "FinalPage",
  components: {
    ResultBlock,
    Feedback,
    Prize,
    GameHeader,
    TopTable,
    SocialShareBlock,
    Jobs,
    Footer,
  },
  data() {
    return {
      rating: 0,
      time: null,
      answears: 18,
      goodAnswears: 15,
      badAnswears: 3,
      asnweared: false,
      feedbackSend: false,
      stats: null,
      top: null,
      confs: [],
      currentConf: null,
      users: [],
      currentUser: null,
      dates: [],
      currentDate: null,
      winners: {},
      currentUsers: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    barWidth() {
      const w = (((100 / this.answears) * this.goodAnswears) / 100) * 600;
      return w;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[
        this.gameInfo.current_level
      ];
    },
  },
  async created() {
    this.$log("created");
    const { data } = await this.$http("/game/get-result");
    this.$log("data", data);
    this.stats = data;
    this.$http.get("/conf").then((resp) => {
      this.currentConf = resp.data?.id;
      if (this.currentConf) {
        this.$http.get(`/conf/${this.currentConf}/top`).then((resp) => {
          this.users = resp.data.users;
          this.currentUser = resp.data.current;
          this.dates = resp.data.conf.dates.map((d) =>
            new Date(d).toLocaleString("ru", { month: "long", day: "numeric" })
          );
          this.winners = Object.fromEntries(
            Object.entries(resp.data.winners).map((x) => [
              new Date(x[0].replace(/-/g, "/")).toLocaleString("ru", {
                month: "long",
                day: "numeric",
              }),
              x[1],
            ])
          );
          const currentDate = new Date().toLocaleString("ru", {
            month: "long",
            day: "numeric",
          });
          if (this.winners[currentDate]) {
            // выводим победителей за текущий день
            this.currentUsers = this.winners[currentDate];
          } else if (this.dates.includes(currentDate)) {
            // если за текущий день нет победителей, выводим топ users
            this.currentUsers = this.users.slice(0, 5);
          } else if (this.dates.length) {
            // если конференция закончилась, выводим победителей последнего дня
            this.currentUsers = this.winners[this.dates[this.dates.length - 1]];
          }
        });
      }
    });
  },
  async mounted() {
    this.$log("mounted");
  },
  methods: {
    async restart() {
      this.$log("handleRestart");
      await this.$store.dispatch("game/finishGame");
      await this.$store.dispatch("game/restartGame");
      this.$router.replace("/game").catch(() => {});
      // location.reload()
    },
    sberLink() {},
    handleWantOffer(answer) {
      this.$http
        .post("/user/set-want-offer", {
          want_offer: answer,
        })
        .then(() => {
          this.asnweared = true;
        });
    },
    share(platform) {
      let url = null;
      switch (platform) {
        case "vk":
          url = "http://vkontakte.ru/share.php?url=";
          break;
        case "tw":
          url = "http://twitter.com/share?url=";
          break;
        case "fb":
          url = "http://www.facebook.com/sharer.php?u=";
          break;
        default:
          break;
      }
      window.open(url + window.location.href);
    },
  },
};
</script>
<style lang="scss" scoped>
.city {
  background-image: url("/images/city-first.png"),
    url("/images/city-first-bg.png");
  background-size: 1500px;
  background-position: bottom;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  max-height: 520px;
}
.blur {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 696px;
  background-image: linear-gradient(
    180deg,
    rgba(35, 1, 42, 0) 0%,
    #090812 100%
  );
  z-index: 10;
}
.final-page {
  position: relative;
  // padding-bottom: 64px;
  & .top-btn {
    z-index: 9999;
    position: absolute;
    top: 8px;
    right: calc(50% - 90px);
  }
  color: #fff;
  background: #090812;
  position: relative;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
  & .top-table {
    max-width: 600px;
    width: 100%;
  }
  &-block {
    background: url("/images/sky-first.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100vw;
    width: 100vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 196px 16px 83px 16px;
    &-content {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      max-width: 1280px;
      z-index: 99;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    &-buttons-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
    &-clouds {
      background-image: url("/images/clouds.png");
      height: 320px;
      width: 100%;
      background-size: 5000px;
      position: absolute;
      top: 0px;
      left: 0;
      background-position-y: -160px;
      background-repeat: repeat;
      // z-index: 1000;
      animation: animCloud 30s infinite linear;
    }
    &-city-bg {
      position: absolute;
      background-image: url("/images/city-first-bg.png");
      background-size: 1400px;
      background-position: bottom -153px center;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 1;
    }
    &-city {
      position: absolute;
      background-image: url("/images/city-first.png");
      background-size: 1280px;
      background-position: bottom -187px center;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0px;
      z-index: 3;
    }
    &-blur-bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        rgba(35, 1, 42, 0) 0%,
        #090812 90%
      );
      z-index: 10;
    }

    &-subtitle {
      font-family: "BlenderPro Thin";
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #f8fafc;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      & p {
        width: 100%;
        max-width: 550px;
        color: #f8fafc;
      }
    }
    & h1 {
      width: 100%;
      text-transform: uppercase;
      text-align: center;
    }
  }
  &-container {
    display: flex;
    justify-content: center;
    position: relative;
    // max-width: 1200px;
    width: 100%;
    z-index: 1000;
    & h2 {
      font-size: 32px;
      line-height: 45px;
    }
    &__result {
      margin-top: 150px;
    }
  }
}
@keyframes animCloud {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1745px;
  }
}
.sber {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
  // height: 158px;
  background-color: #131120;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &-body {
    display: flex;
    flex-wrap: row;
    padding: 25px;
    width: 100%;
  }
  &-text-container {
    margin-left: 30px;
    max-width: 400px;
    width: 100%;
    & h5 {
      width: 100%;
    }
    & p {
      padding: 0;
      margin: 0;
      color: #858a9c;
    }
  }
}
p {
  color: #e0e0e0;
}
h5 {
  color: #e0e0e0;
}
@media screen and (max-width: 768px) {
  .geecko-textarea {
    max-width: 320px;
    min-width: 0;
    width: 100%;
  }
  .final-page {
    padding-bottom: 100px;
    &-container {
      &__result {
        margin-top: 40px;
      }
    }
    &-block {
      padding: 100px 16px 63px 16px;
      background-size: cover;
      & h1 {
        font-size: 26px;
        line-height: 36px;
      }
      &-subtitle {
        & p {
        font-size: 14px;
        line-height: 16px;
        }
      }
      &-buttons-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
      &-city {
        background-size: cover;
        background-position: bottom -40px center;
        background-repeat: no-repeat;
      }
      &-city-bg {
        background-size: contain;
        background-position: bottom 0px center;
        background-repeat: no-repeat;
      }
      &-clouds {
        display: none;
        /* background-image: url("/images/clouds.png");
        height: 320px;
        width: 100%;
        background-size: 7000px;
        position: absolute;
        top: -130px;
        left: 0;
        background-position-y: -60px;
        background-repeat: repeat;
        z-index: 2;
        animation: animCloud 30s infinite linear; */
      }
    }
  }
}
</style>
