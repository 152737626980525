<template>
  <div class="result">
    <div class="result-header">
      <div class="result-header-teamleadus" />
      <p>Дух старейшины</p>
    </div>
    <div class="result-body mb-8">
      <h1 v-if="stats && stats.score" style="font-size: 48px">
        {{ stats.score + "%" }}
      </h1>
      <template v-if="stats">
        <p v-if="stats.quiz.correct <= 5">
          Потомок, знания о прошлых временах не должны сгинуть в пучинах
          киберхаоса. Храни и пронеси в веках знания, которые получил сегодня
        </p>
        <p v-if="stats.quiz.correct >= 6 && stats.quiz.correct <= 9">
          Крупицы информации собираются в единую картину, но их может не хватить
          на будущие битвы. Наши лучшие спецы всегда будут рады помочь тебе!
        </p>
        <p v-if="stats.quiz.correct >= 10 && stats.quiz.correct <= 14">
          Учения нашей общины отпечатались в тебе, но всегда можно стремиться к
          лучшим результатам. Для закрепления материала советуем посидеть на
          Хабре.
        </p>
        <p v-if="stats.quiz.correct >= 15 && stats.quiz.correct <= 19">
          Ты ходишь по лезвию знаний, но важно понимать, что отправной точкой
          любых открытий является желание к познанию нового... Ох, чего это я
          начал разглагольствовать? Убил 3 боссов и ладно.
        </p>
        <p v-if="stats.quiz.correct >= 20 && stats.quiz.correct <= 25">
          Ух, даже я не знал ответов на все вопросы, и откуда бы им взяться в
          воспоминаниях предков. В любом случае, как вернемся - сядем за тетрис,
          лучшая тренировочная платформа.
        </p>
        <p v-if="stats.quiz.correct >= 26 && stats.quiz.correct <= 30">
          Не зря ты был избран спасителем цифровых удобств, твои знания и правда
          выходят за границы моего познания. Не останавливайся на достигнутом,
          ведь ты способен на многое.
        </p>
      </template>
      <!-- <h5 style="margin-top: 20px">Тесты</h5>
      <ResultBar
        v-if="stats"
        :correct="stats && stats.quiz.correct"
        :all="stats && stats.quiz.correct + stats.quiz.incorrect"
      /> -->
      <h5>Битвы с боссами</h5>
      <ResultBar
        v-if="stats && stats.boss"
        :correct="stats.boss.win"
        :all="3"
      />
      <router-link to="/top">
        <!-- Турнирная таблица -->
        <button
          style="border: 1px solid white; border-radius: 5px; padding: 4px; margin-top: 15px; width: 240px;"
        >
          Смотреть результат в топе
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import ResultBar from "@/components/Result/ResultBar";

export default {
  name: "ResultBlock",
  components: {
    ResultBar,
  },
  props: { stats: { type: Object } },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.result {
  max-width: 600px;
  width: 100%;
  // height: 300px;
  background-color: #131120;
  position: relative;
  margin-top: -50px;
  z-index: 100;
  &-header {
    background-color: #2e2c3f;
    height: 40px;
    // width: 100%;
    top: 0;
    padding: 0px 20px;
    padding-bottom: 0px;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    &-teamleadus {
      background: url("/images/teamleadus.png");
      background-repeat: no-repeat;
      // background-position-y: -200px;
      align-self: flex-end;
      width: 80px;
      height: 70px;
      bottom: 0;
      // border: 1px solid red;
    }
    & p {
      color: #e0e0e0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 17px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-top: 20px solid #090812;
      border-left: 20px solid #2e2c3f;
      width: 0;
      z-index: 10;
    }
  }
  &-body {
    padding: 30px;
    padding-bottom: 0px;
    z-index: 100;
    &-bar {
      display: flex;
      height: 50px;
      font-size: 25px;
      font-family: "BlenderPro Bold";
      margin-bottom: 30px;
      margin-top: 5px;
      &-positive {
        background: #424f42;
        border-bottom: 3px solid #40ff90;
        padding-left: 10px;
        height: 50px;
        min-width: 50px;
        // width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &-negative {
        background: #5e4848;
        margin-left: 20px;
        padding-left: 10px;
        border-bottom: 3px solid #ea3344;
        height: 50px;
        min-width: 50px;
        width: 50;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    & p,
    & h5 {
      color: #e0e0e0;
    }
    & h1 {
      padding: 0;
      margin: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .result {
    margin-top: 50px;
    &-header {
      & p {
        font-size: 16px;
        line-height: 120%;
      }
    }
    &-body {
      & h1 {
        font-size: 26px;
        line-height: 36px;
      }
      & h5 {
        font-size: 16px;
        line-height: 120%;
      }
      & p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
</style>