<template>
  <div class="bar-body">
    <div class="bar-body-bar">
      <div
        :style="{ width: barWidth + 'px' }"
        class="bar-body-bar-positive"
      >
        {{ correct }}
        <div class="check" />
      </div>
      <div v-if="correct !== all" class="bar-body-bar-negative">
        {{ wrongResults }}
        <div class="cross" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultBar',
  props: {
    correct: { type: Number, default: 10 },
    all: { type: Number, default: 10 },
  },
  data: () => ({
    width: 1000,
  }),
  computed: {
    wrongResults() {
      return this.all - this.correct;
    },
    barWidth() {
      const n = (((100 / this.all) * this.correct) / 100) * this.width;
      return n * 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.check {
  background: url('/images/check.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  height: 45px;
  width: 45px;
}
.cross {
  background: url('/images/cross.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  height: 45px;
  width: 45px;
}
.bar-body {
  padding-bottom: 0px;
  z-index: 100;
  width: 100%;
  &-bar {
    display: flex;
    flex-wrap: row;
    height: 50px;
    width: 100%;
    font-size: 25px;
    font-family: 'BlenderPro Bold';
    margin-bottom: 10px;
    margin-top: 5px;
    &-positive {
      background: #424f42;
      border-bottom: 3px solid #40ff90;
      padding-left: 10px;
      height: 50px;
      min-width: 50px;
      // width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
    }
    &-negative {
      background: #5e4848;
      margin-left: 10px;
      padding-left: 5px;
      border-bottom: 3px solid #ea3344;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
    }
  }
  & p {
    color: #e0e0e0;
  }
  & h1 {
    padding: 0;
    margin: 0;
  }
}
</style>
