<template>
  <div class="banner">
    <div class="banner-body-block">
      <div class="banner-body-text-container">
        <h5 style="font-size: 48px;line-height: 1" class="mb-12">{{ title }}</h5>
        <!-- <p>
          Покажи свои знания в битвах с боссами
        </p> -->
      </div>
    </div>
    <div class="banner-body">
      <!-- <h5>
        Хочешь посмотреть, как справятся твои друзья?<br />Поделись с ними
      </h5> -->
      <div class="links-block">
        <button class="social-share-item vkontakte" @click="share('vk')" />
        <button class="social-share-item facebook" @click="share('fb')" />
        <button class="social-share-item twitter" @click="share('tw')" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SocialShareBlock",
  components: {},
  data() {
    return {
      title: ""
    };
  },
  computed: {},
  methods: {
    share(platform) {
      let url = null;
      switch (platform) {
        case "vk":
          url = "http://vkontakte.ru/share.php?url=";
          break;
        case "tw":
          url = "http://twitter.com/share?url=";
          break;
        case "fb":
          url = "http://www.facebook.com/sharer.php?u=";
          break;
        default:
          break;
      }
      window.open(url + window.location.href);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  max-width: 600px;
  width: 100%;
  background-color: #131120;
  position: relative;
  &-body {
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    & h5 {
      // font-size: 18px;
      // line-height: 24px;
      color: #e0e0e0;
    }
    &-block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      background: url("/images/og-vk.jpg?1");
      background-size: cover;
      background-repeat: no-repeat;
      max-width: 600px;
      width: 100%;
      height: 315px;
      // border: 1px solid red;
    }
    &-text-container {
      display: flex;
      flex-wrap: wrap;
      padding: 30px;
      width: 100%;
      max-width: 500px;
      // line-height: 1.5;
      & h5 {
        margin: 0px;
        font-size: 28px;
        // height: 60px;
        text-transform: uppercase;
        width: 100%;
        color: #ffffff;
      }
      & p {
        // margin: 0px;
        color: #e0e0e0;
        width: 100%;
        height: 40px;
        font-size: 22px;
        max-width: 180px;
      }
    }
  }
}

.links-block {
  display: flex;
  flex-wrap: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
}
.social-share-item {
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}
.vkontakte {
  background-image: url("/images/VK.png");
}
.facebook {
  background-image: url("/images/FB.png");
}
.twitter {
  background-image: url("/images/TW.png");
}
@media screen and (max-width: 768px) {
  .banner {
    &-body {
      &-block {
        height: 200px;
        background-position: bottom left;
      }
      & h5 {
        font-size: 16px;
        line-height: 120%;
      }
      &-text-container {
        & h5 {
          font-size: 16px;
          line-height: 120%;
        }
        & p {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
