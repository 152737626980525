<template>
  <div class="flex xs:flex-col sm:flex-row">
    <div class="sber-logo" />
    <div class="flex flex-col sm:pl-9 xs:pb-9">
      <h2 style="font-size: 24px">Работа в Сбере</h2>
      <p>Сбер находится в постоянном поиске талантливых специалистов из разных областей. Посмотри кого мы ищем сейчас</p>
      <a @click="$router.push('/job')" target="_blank">
        <button
          style="border: 1px solid white; border-radius: 5px; padding: 4px; margin-top: 15px; width: 200px;"
        >
          Смотреть вакансии
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Jobs',
  methods: {
    // jobLink() {
    //   this.$router.push('/job')
    // }
  }
}
</script>

<style lang="scss" scoped>
p {
  color: #e0e0e0;
}
.sber-logo {
  background: url("/images/sberbank-logo.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 200px;
  // border: 1px solid red;
  height: 200px;
  margin: 10px 0px;
}
@media (min-width: 0px) {
  .sber-logo {
    height: 100px;
  }
}
@media (min-width: 640px) {
  .sber-logo {
    height: 200px;
  }
}
</style>