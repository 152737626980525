<template>
  <div class="final-page__prize">
    <div class="final-page__prize-title">спецзадание от Сбера</div>
    <div class="final-page__prize-subtitle">
      Пройди специальное задание и получи возможность выиграть 
      <a href="https://salute.sber.ru/sberportal/" target="_blank">CберПортал</a>
    </div>
    <router-link to="/task" class="geecko-button"
      >{{actionText}}</router-link
    >
  </div>
</template>

<script>
export default {
  name: 'Prize',
  // props: ['action-text']
  props: {
    actionText: {
      type: String,
      default: 'Подробнее'
    }
  }
}
</script>

<style lang="scss" scoped>
.final-page__prize {
  margin-bottom: 40px;
  background-image: url("/images/smartdisplay2.png"), url("/images/ellipse.svg");
  background-repeat: no-repeat;
  background-size: 209px, 534px;
  background-position: bottom right, center right -53px;
  max-width: 600px;
  width: 100%;
  padding: 32px 182px 24px 32px;
  & .geecko-button {
    margin: 0;
  }
  &-title {
    font-size: 32px;
    line-height: 120%;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "BlenderPro Bold";
    margin-bottom: 6px;
  }
  &-subtitle {
    font-size: 20px;
    line-height: 28px;
    font-family: "BlenderPro Thin";
    color: #e0e0e0;
    margin-bottom: 16px;
    & a {
      color: var(--primary);
      position: relative;
      &:hover {
        color: var(--secondary);
      }
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        background: currentColor;
        width: 100%;
        left: 0;
        bottom: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .final-page__prize {
    background-size: 109px, 167px;
    background-position: center right, center right -53px;
    padding: 32px 100px 24px 32px;
    & .geecko-button {
      margin: 0;
    }
    &-title {
      font-size: 16px;
      line-height: 120%;
    }
    &-subtitle {
      font-size: 14px;
      line-height: 16px;
      & a {
        font-size: inherit;
      }
    }
  }
}
</style>